import React, { useState } from "react";
import "./App.css";
import { isLogged } from "./Red";
import { supabase } from "./Red";
import swal from "sweetalert";

const Stadiums = ({ id, capacity, name, location }) => {
  const [edit, EditMode] = useState(false);

  async function DeleteEntry() {
    try {
      const error = await supabase.from("Stadiums").delete().eq("id", id);
      if (error) {
        if (error.status == "409") {
          swal(
            "Ошибка!",
            "Для удаления этого стадиона, нужно удалить матч(и) на нём.",
            "error"
          );
        } else if (error.status == "204") {
          window.location.reload();
        } else {
          console.log(error);
        }
      }
      else{
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function EditStadium() {
    const name = document.getElementById("stadiumname");
    const capacity = document.getElementById("stadiumcapacity");
    const location = document.getElementById("stadiumlocation");
    if (
      name.value !== "" &&
      capacity.value !== "" &&
      location.value !== ""
    ) {
      try {
        const { error } = await supabase
          .from("Stadiums")
          .update({
            capacity: capacity.value,
            name: name.value,
            location: location.value
          })
          .eq("id", id);
        if (error) console.log(error);

        window.location.reload();
        
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось сохранить изменения.", "error");
      }
    }
  }

  return (
    <div className="card">
      {edit ? (
        <>
          <h2 className="tittle">
            <strong>Название стадиона:</strong> <input id="stadiumname" defaultValue={name}/>
          </h2>
          <p className="info">
            <strong>Вместимость:</strong>{" "}
            <input id="stadiumcapacity" type="number" defaultValue={capacity}/>
          </p>
          <p className="info">
            <strong>Местоположение:</strong> <input id="stadiumlocation" defaultValue={location}/>
          </p>
          <div className="card_button">
            <button className="supp_button" onClick={() => EditStadium()}>
              Сохранить
            </button>
            <button className="supp_button" onClick={() => EditMode(false)}>
              Отменить
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="tittle">{name}</h2>
          <p className="info">
            <strong>Вместимость:</strong> {capacity} чел.
          </p>
          <p className="info">
            <strong>Местоположение:</strong> {location}
          </p>
          {isLogged && (
            <div className="card_button">
              <button className="supp_button" onClick={() => EditMode(true)}>
                Изменить
              </button>
              <button className="supp_button" onClick={() => DeleteEntry()}>
                Удалить
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Stadiums;
