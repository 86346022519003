import React, { useState } from "react";
import "./App.css";
import { isLogged } from "./Red";
import { supabase } from "./Red";
import swal from "sweetalert";

const Coaches = ({ id, name, age, experience }) => {
  const [edit, EditMode] = useState(false);

  async function DeleteEntry() {
    try {
      const error = await supabase.from("Coaches").delete().eq("id", id);
      if (error) {
        if (error.status == "409") {
          swal(
            "Ошибка!",
            "Для удаления этого тренера, нужно удалить спортсмена(нов) с ним.",
            "error"
          );
        } else if (error.status == "204") {
          window.location.reload();
        } else {
          console.log(error);
        }
      }
      else{
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function EditCoach() {
    const name = document.getElementById("coachname");
    const age = document.getElementById("coachage");
    const experience = document.getElementById("coachexperience");
    if (name.value !== "" && age.value !== "" && experience.value !== "") {
      try {
        const { error } = await supabase
          .from("Coaches")
          .update({
            name: name.value,
            age: age.value,
            experience: experience.value,
          })
          .eq("id", id);
        if (error) console.log(error);

        window.location.reload();
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось сохранить изменения.", "error");
      }
    }
  }

  return (
    <div className="card">
      {edit ? (
        <>
          <h2 className="tittle">
            <strong>Фио тренера:</strong> <input id="coachname" defaultValue={name}/>
          </h2>
          <p className="info">
            <strong>Возраст:</strong> <input id="coachage" type="number" defaultValue={age}/>
          </p>
          <p className="info">
            <strong>Опыт работы:</strong>{" "}
            <input id="coachexperience" type="number" defaultValue={experience}/>
          </p>
          <div className="card_button">
            <button className="supp_button" onClick={() => EditCoach()}>
              Сохранить
            </button>
            <button className="supp_button" onClick={() => EditMode(false)}>
              Отменить
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="tittle">{name}</h2>
          <p className="info">
            <strong>Возраст:</strong> {age} лет
          </p>
          <p className="info">
            <strong>Опыт работы:</strong> {experience} лет
          </p>
          {isLogged && (
            <div className="card_button">
              <button className="supp_button" onClick={() => EditMode(true)}>
                Изменить
              </button>
              <button className="supp_button" onClick={() => DeleteEntry()}>
                Удалить
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Coaches;
