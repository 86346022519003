import React, { useEffect, useState } from "react";
import { Centrifuge, UnauthorizedError } from "centrifuge";
import WebSocket from "ws";

const apiKey = "893fe7e4-30c2-49c7-b5b4-5af607ccc681";

const Table = () => {
  const [data, setData] = useState([]);

  const centrifuge = new Centrifuge(
    "ws://ws.lis-skins.com/connection/websocket",
    {
      getToken: async function () {
        const res = await fetch(
          "http://g922173g.beget.tech/proxy.php",
          {
            headers: {
              Authorization: "Bearer " + apiKey,
            },
          }
        );
        if (!res.ok) {
          if (res.status === 403) {
            throw new UnauthorizedError();
          }
          throw new Error(`Unexpected status code ${res.status}`);
        }
        const json = await res.json();
        return json.data.token;
      },
    }
  );

  function Connect() {
    const sub = centrifuge.newSubscription("public:obtained-skins");
    centrifuge
      .on("connecting", (ctx) => {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      })
      .on("connected", (ctx) => {
        console.log(`connected over ${ctx.transport}`);
        sub.subscribe();
      })
      .on("error", (error) => {
        console.error("Centrifuge error:", error);
      })
      .connect();

    sub.on("publication", (ctx) => {
      const skin = ctx.data;
      if (skin.event === "obtained_skin_added") {
        setData((prevData) => [...prevData, skin]);
      }
    });
  }

  return (
    <div>
      <h1>Полученные скины</h1>
      <button onClick={() => Connect()}>Connect</button>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th>Цена</th>
            <th>Float</th>
            <th>Создано</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>${item.price}</td>
              <td>{item.item_float}</td>
              <td>{new Date(item.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
