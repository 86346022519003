import React, { useState } from "react";
import "./App.css";
import { isLogged } from "./Red";
import { supabase } from "./Red";
import swal from "sweetalert";

const Athletes = ({ id, name, age, coach, coaches }) => {
  const [edit, EditMode] = useState(false);
  const [editCoach, setEditCoach] = useState(coach.id);

  async function DeleteEntry() {
    console.log(id);
    try {
      const error = await supabase.from("Athletes").delete().eq("id", id);
      if (error) {
        if (error.status == "409") {
          swal(
            "Ошибка!",
            "Для удаления этого спортсмена, нужно удалить матч(и) с ним.",
            "error"
          );
        } else if (error.status == "204") {
          window.location.reload();
        } else {
          console.log(error);
        }
      }
      else{
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function EditAthlet() {
    const name = document.getElementById("athletname");
    const age = document.getElementById("athletage");
    if (name.value !== "" && age.value !== "" && editCoach !== "") {
      try {
        const { error } = await supabase
          .from("Athletes")
          .update({
            name: name.value,
            age: age.value,
            coach_id: editCoach,
          })
          .eq("id", id);
        if (error) console.log(error);

        window.location.reload();
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось сохранить изменения.", "error");
      }
    }
  }

  return (
    <div className="card">
      {edit ? (
        <>
          <h2 className="tittle">
            <strong>Фио спортсмена:</strong> <input id="athletname" defaultValue={name}/>
          </h2>
          <p className="info">
            <strong>Возраст спортсмена:</strong>{" "}
            <input id="athletage" type="number" defaultValue={age}/>
          </p>
          <p className="info">
            <strong>Тренер:</strong>{" "}
            <select
              id="athletcoach"
              defaultValue={coach.id}
              onChange={(coach) => setEditCoach(coach.target.value)}
            >
              <option value="">Выберите тренера</option>
              {coaches.map((coacch) => (
                <option value={coacch.id}>{coacch.name}</option>
              ))}
            </select>
          </p>
          <div className="card_button">
            <button className="supp_button" onClick={() => EditAthlet()}>
              Сохранить
            </button>
            <button className="supp_button" onClick={() => EditMode(false)}>
              ОТменить
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="tittle">{name}</h2>
          <p className="info">
            <strong>Возраст:</strong> {age} лет
          </p>
          <p className="info">
            <strong>Тренер:</strong> {coach.name}
          </p>
          {isLogged && (
            <div className="card_button">
            <button className="supp_button" onClick={() => EditMode(true)}>
                Изменить
              </button>
              <button className="supp_button" onClick={() => DeleteEntry()}>
                Удалить
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Athletes;
