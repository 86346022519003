import React, { useState } from "react";
import "./App.css";
import { isLogged } from "./Red";
import { supabase } from "./Red";
import swal from "sweetalert";

const Games = ({
  id,
  sport,
  athlet1,
  athlet2,
  date,
  round_number,
  stadium,
  athletes,
  stadiums,
}) => {

  const [edit, EditMode] = useState(false);
  const [editFirstAthlete, setEditFirstAthlete] = useState(athlet1.id);
  const [editSecondAthlete, setEditSecondAthlete] = useState(athlet2.id);
  const [editStadiumGame, setEditStadiumGame] = useState(stadium.id);

  async function DeleteEntry() {
    try {
      const error = await supabase.from("Games").delete().eq("id", id);
      if (error) {
        if (error.status == "409") {
          swal(
            "Ошибка!",
            "Для удаления этого матча, нужно удалить его результаты.",
            "error"
          );
        } else if (error.status == "204") {
          window.location.reload();
        } else {
          console.log(error);
        }
      }
      else{
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function EditGame() {
    const name = document.getElementById("gameName");
    const gamedate = document.getElementById("gameDate");
    const rounds = document.getElementById("rounds_of_number");
    console.log("enter");
    if (
      name.value !== "" &&
      gamedate.value !== "" &&
      rounds.value !== "" &&
      editFirstAthlete !== "" &&
      editSecondAthlete !== "" &&
      editStadiumGame !== ""
    ) {
      try {
        console.log("enter");
        const { error } = await supabase
          .from("Games")
          .update({
            Sport: name.value,
            game_date: gamedate.value,
            number_of_rounds: rounds.value,
            stadium_id: editStadiumGame,
            athletes1_id: editFirstAthlete,
            athletes2_id: editSecondAthlete,
          })
          .eq("id", id);

        if (error) console.log(error);

        window.location.reload();
        
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось сохранить изменения.", "error");
      }
    }
  }

  return (
    <div className="card">
      {edit ? (
        <>
          <h2 className="tittle">
            <strong>Название спорта:</strong>{" "}
            <input id="gameName" defaultValue={sport} />
          </h2>
          <p className="info">
            <strong>Первый спортсмен:</strong>{" "}
            <select
              id="firstathlet"
              defaultValue={athlet1.id}
              onChange={(athlete) => setEditFirstAthlete(athlete.target.value)}
            >
              {athletes.map((athlete) => (
                <option value={athlete.id}>{athlete.name}</option>
              ))}
            </select>
          </p>
          <p className="info">
            <strong>Второй спортсмен:</strong>{" "}
            <select
              id="secondathlet"
              defaultValue={athlet2.id}
              onChange={(athlete) => setEditSecondAthlete(athlete.target.value)}
            >
              {athletes.map((athlete) => (
                <option value={athlete.id}>{athlete.name}</option>
              ))}
            </select>
          </p>
          <p className="info">
            <strong>Дата:</strong>{" "}
            <input id="gameDate" type="date" defaultValue={date} />
          </p>
          <p className="info">
            <strong>Количество раундов:</strong>{" "}
            <input
              id="rounds_of_number"
              defaultValue={round_number}
              type="number"
            />
          </p>
          <p className="info">
            <strong>Стадион:</strong>{" "}
            <select
              id="gamestadium"
              defaultValue={stadium.id}
              onChange={(stadium) => setEditStadiumGame(stadium.target.value)}
            >
              <option value="">Выберите стадион</option>
              {stadiums.map((stadium) => (
                <option value={stadium.id}>{stadium.name}</option>
              ))}
            </select>
          </p>
          <div className="card_button">
            <button className="supp_button" onClick={() => EditGame()}>
              Сохранить
            </button>
            <button className="supp_button" onClick={() => EditMode(false)}>
              Отменить
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="tittle">{sport}</h2>
          <p className="info">
            <strong>Первый спортсмен:</strong> {athlet1.name}
          </p>
          <p className="info">
            <strong>Второй спортсмен:</strong> {athlet2.name}
          </p>
          <p className="info">
            <strong>Дата:</strong> {date}
          </p>
          <p className="info">
            <strong>Количество раундов:</strong> {round_number}
          </p>
          <p className="info">
            <strong>Стадион:</strong> {stadium.name}
          </p>
          {isLogged && (
            <div className="card_button">
              <button className="supp_button" onClick={() => EditMode(true)}>
                Изменить
              </button>
              <button className="supp_button" onClick={() => DeleteEntry()}>
                Удалить
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Games;
